<template>
  <div
    :class="{
      'bg-dark': theme === 'dark',
      'bg-blue-900': theme === 'darker',
      'bg-[#f4f4f4]': theme === 'gray'
    }"
  >
    <atoms-card
      shadow="sm"
      rounded="lg"
      class="flex"
      :size="component.cardSize"
      :class="[
        component.theme,
        component.direction,
        {
          '!flex-row': ['white', 'purple'].includes(theme)
        }
      ]"
      :theme="['dark', 'darker', 'gray'].includes(theme) ? 'plain' : 'default'"
    >
      <NuxtImg
        v-if="type === 'lowest'"
        src="/icons/price-lowest.svg"
        alt="lowest price"
        width="63.99"
        height="63.99"
        :class="[
          component.icon,
          {
            'mb-[0.625rem]': direction === 'column',
            'm-auto': direction === 'row',
            'min-w-[3.75rem] w-[3.75rem] h-[3.75rem]': ['purple', 'white'].includes(theme)
          }
        ]"
        :loading="imageLazyLoad ? 'lazy' : 'eager'"
      />

      <NuxtImg
        v-else-if="type === 'markups'"
        src="/icons/no-markup-guarantee.svg"
        alt="no markup guarantee"
        width="63.99"
        height="63.99"
        :class="[
          component.icon,
          {
            'mb-[0.625rem]': direction === 'column',
            'm-auto': direction === 'row',
            'min-w-[4.5rem] w-[4.5rem] h-[4.5rem]': ['purple', 'white'].includes(theme)
          }
        ]"
        :loading="imageLazyLoad ? 'lazy' : 'eager'"
      />

      <Nuxt-Img
        v-else
        src="/icons/price-direct-2.svg"
        alt="direct price"
        width="63.99"
        height="63.99"
        :class="[
          component.icon,
          {
            'mb-[0.625rem]': direction === 'column',
            'm-auto': direction === 'row',
            'min-w-[3.75rem] w-[3.75rem] h-[3.75rem]': theme === 'purple'
          }
        ]"
        :loading="imageLazyLoad ? 'lazy' : 'eager'"
      />

      <div
        class="flex flex-col"
        :class="[
          component.content,
          component.contentByTheme
        ]"
      >
        <p
          class="font-bold mb-2"
          :class="[
            component.title,
            component.titleByTheme
          ]"
        >
          {{ title }}
        </p>

        <div
          v-if="$slots.default"
        >
          <slot />
        </div>
      </div>
    </atoms-card>
  </div>
</template>

<script setup>
const { devices } = deviceChecker()

defineOptions({
  name: 'MoleculesDirectPrice'
})

const props = defineProps({
  type: {
    type: String,
    default: 'direct',
    validator: value => ['direct', 'lowest', 'markups'].includes(value)
  },

  direction: {
    type: String,
    default: 'column',
    validator: value => ['column', 'row', 'rowFull'].includes(value)
  },

  theme: {
    type: String,
    default: 'light',
    validator: value => ['light', 'dark', 'darker', 'purple', 'white', 'gray'].includes(value)
  },

  imageLazyLoad: {
    type: Boolean,
    default: false
  },

  title: {
    type: String,
    default: ''
  }
})

const component = computed(() => {
  const value = {
    direction: props.direction,
    theme: props.theme
  }

  const direction = getKey(value.direction, {
    column: 'flex-col',
    row: 'max-w-wrapper-sm mx-auto',
    rowFull: 'w-full flex-col lg:flex-row'
  })

  const title = getKey(value.direction, {
    column: 'text-lg',
    row: 'text-xl lg:text-2xl',
    rowFull: 'text-lg'
  })

  const titleByTheme = getKey(value.theme, {
    white: '!text-base lg:text-base !mb-0 lg:!mb-1',
    purple: 'underline text-base lg:text-base !mb-0 lg:!mb-1'
  })

  const content = getKey(value.direction, {
    row: 'ml-4 pl-4 border-solid border-l-primary border-l-4',
    rowFull: 'lg:ml-4 mt-4 lg:mt-0'
  })

  const contentByTheme = getKey(value.theme, {
    purple: 'ml-2 lg:!ml-2 !my-0 lg:mb-2',
    white: 'ml-4 lg:ml-3 !my-0 lg:mb-2'
  })

  const icon = getKey(value.direction, {
    column: 'w-16 h-16',
    row: 'w-[5.25rem] h-[5.25rem] min-w-[5.25rem] min-h-[5.25rem] md:w-20 md:w-20 md:min-w-20 md:min-w-20',
    rowFull: 'w-16 min-w-[4rem] h-16'
  })

  const theme = getKey(value.theme, {
    light: 'bg-white text-dark',
    dark: 'bg-dark text-white',
    darker: 'bg-blue-900 text-white',
    purple: '!bg-purple-80 text-white pl-2 pr-3 py-3 items-center',
    white: 'text-dark !py-3 lg:!py-4 !px-4 lg:!px-2 items-center border border-gray-400 !shadow-none',
    gray: '!shadow-none rounded-none border-none'
  })

  const cardSize = (() => {
    if (devices.isMobile.value && props.type === 'lowest') {
      return 'md'
    }

    return 'lg'
  })()

  return {
    title,
    titleByTheme,
    icon,
    direction,
    theme,
    content,
    contentByTheme,
    cardSize
  }
})

const title = computed(() => {
  if (props.title) {
    return props.title
  }
  if (props.type === 'markups') {
    return `No ${strToCapitalize(props.type)} Guarantee`
  }
  return `${strToCapitalize(props.type)} Price Guarantee`
})
</script>
